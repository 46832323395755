import React from 'react';
const TopupItem = (props) => {
  const {amount, setAmount, containerClassName, currentAmount, icon} = props;

  const handleOnClick = () => {
    setAmount(amount);
  };

  return (
    <div onClick={handleOnClick} className="d-flex flex-column topup-item">
      <div
        className={`d-flex justify-content-center align-items-center
        topup-item-container px-1 ${containerClassName} ` + (currentAmount === amount ? 'topup-item-container-active' : '')}
      >
        <img className="topup-item-img" alt="izipay icon" src={icon}></img>
      </div>
      <p className={`topup-item-price mt-1 align-self-center ${currentAmount === amount ? 'topup-item-price-active' : ''}`}>
        {`Rp`+ amount.toLocaleString()}</p>
    </div>
  );
};
export default TopupItem;
