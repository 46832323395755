import React from 'react';

const ButtonWithIcon = (props) => (
  <div onClick={props.onClick} onKeyPress={props.onKeyPress} className={`button align-items-center justify-content-between w-75  px-2 ${props.className} ${props.buttonClassName}`}> {/* eslint-disable-line */}
    <div className={`d-flex flex-column justify-content-center py-1 
      button-icon-container`}>
      <img className="align-self-center button-icon-img" alt="tumbler type" src={props.icon}></img>
    </div>
    <a href={props.link} target="_blank" rel="noopener noreferrer" >
      {props.text}
    </a>
  </div>
);

export default ButtonWithIcon;
