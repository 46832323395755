import React, {useEffect, useState} from 'react';
import TopupItem from './TopupItem';
import iconCreditSmall from '../../images/app/icon_credit_small.png';
import iconCreditMedium from '../../images/app/icon_credit_medium.png';
import iconCreditLarge from '../../images/app/icon_credit_large.png';

const TopupOption = (props) => {
  const {onUpdateTopupOption, className} = props;
  const [currentAmount, setCurrentAmount] = useState(10000);

  const onSetAmount = (amount) => {
    onUpdateTopupOption(amount);
    setCurrentAmount(amount);
  };

  useEffect(() => {
    onUpdateTopupOption(0);
    // eslint-disable-next-line
    }, [0])

  return (
    <div
      className={`d-flex flex-column 
      align-items-center w-100 ` + className}
    >
      <div className="d-flex justify-content-between">
        <TopupItem amount={10000} setAmount={onSetAmount} icon={iconCreditSmall}
          containerClassName="mx-1" currentAmount={currentAmount}
        />
        <TopupItem amount={20000} setAmount={onSetAmount}
          icon={iconCreditMedium}
          containerClassName="mx-1" currentAmount={currentAmount}
        />
        <TopupItem amount={50000} setAmount={onSetAmount} icon={iconCreditLarge}
          containerClassName="mx-1" currentAmount={currentAmount}
        />
      </div>
    </div>
  );
};
export default TopupOption;
