import React, {useEffect, useState} from 'react';
import TopupOption from '../../components/app/TopupOption';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import {getByFetch, post} from '../../helpers/api';
import {
  UNAUTHORIZED_STATUS_CODE,
} from '../../helpers/constants';
import {GET_USER_BONUS_TOPUP_AVAILABLE, GET_USER_URL, PAYMENT_TOKEN_URL} from '../../helpers/endpoints';
import {navigateToHome} from '../../helpers/navigation';
import gopayIcon from '../../images/app/payment-method-gopay.png';
import qrisIcon from '../../images/app/payment-method-qris.png';
import shopeePayIcon from '../../images/app/payment-method-shopeepay.png';

const Topup = () => {
  const [topupAmount, setTopupAmount] = useState(10000);
  const [isTopupBonusAvailable, setTopupBonusAvaibility] = useState(false);


  async function fetchUserData() {
    const response = await getByFetch(GET_USER_URL);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (!isAuthorized) {
      navigateToHome();
    }
  }

  async function postCheckout(enabledPayments, uiMode = 'auto') {
    const data = {
      'grossAmount': topupAmount,
      'itemDetails': [
        {
          'id': `topup-${Date.now()}`,
          'price': topupAmount,
          'quantity': 1,
          'name': 'Izipay',
          'merchantName': 'Izifill',
        },
      ],
      'enabledPayments': enabledPayments,
    };

    const response = await post(PAYMENT_TOKEN_URL, data);
    if (response.ok) {
      const dataResponse = await response.json();
      const token = dataResponse.paymentToken;

      snap.pay(token, {
        onSuccess: function(result) {
          console.log('success'); console.log(result);
          navigateToHome();
        },
        onPending: function(result) {
          console.log('pending'); console.log(result);
        },
        onError: function(result) {
          console.log('error'); console.log(result);
        },
        onClose: function() {
          console.log('customer closed the popup without finishing the payment');
        },
        uiMode: uiMode,
      });
    }
  }

  const updateTopUpAmount = (amount) => {
    setTopupAmount(amount);
  };

  async function fetchTopupAvaibility() {
    const response = await getByFetch(GET_USER_BONUS_TOPUP_AVAILABLE);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setTopupBonusAvaibility(data);
    }
  }

  useEffect(() => {
    fetchUserData();
    updateTopUpAmount(10000);
    fetchTopupAvaibility();
  }, []);

  return (
    <div className="topup-container d-flex flex-column">
      <h2 className="px-1 mt-7">IZIPay Top Up</h2>
      <div className="px-1 mt-1 d-flex flex-column align-items-center">
        <h5 className="topup-amount-title">Choose your amount</h5>
        <TopupOption className="mb-2 mt-2" onUpdateTopupOption={updateTopUpAmount} />

      </div>

      <div className='py-1 d-flex flex-column align-items-center topup-summary'>
        <h6>Top Up amount</h6>
        <h2 className="topup-amount-text">{`Rp` + topupAmount.toLocaleString()}</h2>
        <hr className="my-0" />
      </div>

      <div className='mt-2 d-flex flex-column align-items-center w-100'>
        <h6>Choose Top Up Channel</h6>
        <ButtonWithIcon icon={gopayIcon} onClick={() => postCheckout(['gopay'])} className="mt-1 d-flex topup-button" text="GOPAY" buttonClassName="button-secondary" />
        <ButtonWithIcon icon={shopeePayIcon} onClick={() => postCheckout(['shopeepay'])} className="mt-1 d-flex topup-button" text="ShopeePay" buttonClassName="button-secondary" />
        <ButtonWithIcon icon={qrisIcon} onClick={() => postCheckout(['gopay'], 'qr')} className="mt-1 d-flex topup-button" text="QRIS" buttonClassName="button-secondary" />
      </div>

      {isTopupBonusAvailable && <p className="mt-2 ta-center text-info">&#9432; Keep Hydrated! Get a one-time 100% cashback up to Rp 20.000 until 31 October 2022 </p> }
    </div>
  );
};
export default Topup;
